import React, { useContext, useMemo } from "react";
// import { graphql } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "@components/Layout";
// import StockLevel from "@components/StockLevel";
import TransitionLink from "../components/TransitionLink";
import { myContext } from "../context/provider";

import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import client from "../components/appolloClient";

const shopPage = gql`
  query MyQuery {
    entries(slug: "shop") {
      id
      ... on shop_shop_Entry {
        id
        productCollections {
          ... on productCollections_product_BlockType {
            id
            product {
              title
              uri
              defaultPriceAsCurrency
              ... on collaborations_Product {
                id
                productEditions
                price {
                  ... on price_price_BlockType {
                    id
                    audPrice
                    usdPrice
                    euroPrice
                  }
                }
              }
              variants {
                ... on collaborations_Variant {
                  id
                  stock
                }
              }
            }
            comingSoon
            featureImage {
              ... on uploads_Asset {
                id
                url
                title
              }
            }
          }
        }
      }
    }
    seomatic {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaSiteVarsContainer
      frontendTemplateContainer
    }
  }
`;

const ShopTemplate = () => {
  const { currencys } = useContext(myContext)
  // Apollo fetch data
  const { loading, error, data } = useQuery(shopPage, { client });

  if (loading) {
    return <p>.</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }


  const { productCollections } = data?.entries[0];
  const seomatic = data?.seomatic;
  const activeProduct = [];



  const price = data?.entries[0]?.productCollections[0].product[0].price[0];

  productCollections.forEach((obj) => {
    if (Object.keys(obj.product).length !== 0) {
      activeProduct.push(obj);
    }
  });


  /* Story block component: either a link or static image */
  const StockLevel = ({ stock = 0 }) => (
    <div>
      Edition of <span className="stock-level">{stock}</span> only
    </div>
  );

  const StoryBlock = ({
    image,
    title,
    productEditions,
    defaultPriceAsCurrency,
    comingSoon,
    price,
  }) => (
    <>
      <img
        src={image}
        alt=""
        className={`w-full h-full ${comingSoon ? "image--greyscale" : ""}`}
      />
      <div className="mt-2 mb-0.5 font-bold">{title}</div>
      {comingSoon && <div className="mt-2">Coming Soon</div>}
      {!comingSoon && productEditions && <StockLevel stock={productEditions} />}
      <div className="mt-1">
        {currencys == "EUR"
          ? `€ ${price[0]?.euroPrice}`
          : currencys == "USD"
          ? `$ ${price[0]?.usdPrice}`
          : currencys == "AUD"
          ? `A$ ${price[0]?.audPrice}`
          : ""}
      </div>
    </>
  );
  

  return (
    // <h1>Shop</h1>
    <Layout showNav={true} seomatic={seomatic}>
      <div className="container mx-auto overflow-hidden md:pt-32 pt-28">
        <div className="md:px-0 px-6">
          <div className="flex items-center justify-center flex-wrap">
            {/* Loop and render each product */}
            
            {activeProduct.map((product, i) => {
              const { featureImage, product: products, comingSoon } = product;
          
              const productItem = products[0]; 
              const img = product.featureImage[0]; 

              return (
                <div
                  key={i}
                  className={`sm:w-5/12 lg:w-1/4 w-full mb-auto pb-7 md:pb-14 sm:mx-3 ${
                    comingSoon ? "text-grey" : ""
                  }`}
                >
                  {/* Link wrapper */}
                  {!comingSoon && productItem?.uri && (
                    <TransitionLink key={i} to={`/${productItem.uri}`}>
                      <StoryBlock
                        image={img?.url} 
                        title={productItem.title}
                        productEditions={productItem.productEditions}
                        defaultPriceAsCurrency={
                          productItem.defaultPriceAsCurrency
                        }
                        price={productItem.price}
                        comingSoon={comingSoon}
                        eager={i > 2}
                      />
                    </TransitionLink>
                  )}

                  {/* Static image */}
                  {comingSoon && (
                    <StoryBlock
                      image={img.url} 
                      title={productItem.title}
                      productEditions={productItem.productEditions}
                      defaultPriceAsCurrency={
                        productItem.defaultPriceAsCurrency
                      }
                      comingSoon={comingSoon}
                      eager={i > 2}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};



export default ShopTemplate;
